import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const FETCH_USER = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      name
      email
      avatar_image
      country_id
      links {
        id
        url
        link_type_id
      }
      teams {
        id
        name
        avatar_image
      }
      tournaments {
        id
        name
        discipline {
          id
          slug
        }
       }
    }
  }
`

export const FETCH_ME = gql`
    query Me {
        me {
            id
            name
            email
            avatar_image
            country_id
            country {
                name
            }
            links {
                id
                link_type_id
                url
            }
            invites {
                ... on TeamMembershipInvite {
                    id
                    token
                    team {
                        id
                        name
                        avatar_image
                    }
                }
                ... on TournamentInvite {
                    id
                    token
                    tournament_id
                    participant_id
                    participant {
                        id
                        name
                        participant_type
                        participant {
                            ... on Team {
                                id
                                name
                            }
                            ... on User {
                                id
                                name
                            }
                        }
                    }
                    tournament {
                        id
                        name
                        status_id
                        organizer_id
                        organizer {
                            id
                            name
                        }
                        discipline {
                            id
                            slug
                        }
                    }
                }
                ... on OrganizerMembershipInvite {
                    id
                    token
                    organizer {
                        id
                        name
                    }
                }
               
            }
            
          
            friend_invites {
              id
              user {
                id
                name
                avatar_image
              }
            }
          
            
            organizers {
                id
                name
                avatar_image
                user_id
                members {
                    id
                    user_id
                    role {
                        id
                        title
                    }
                    user {
                        id
                        name
                        avatar_image
                    }
                }
            }
            teams {
                id
                name
                avatar_image
                owner_id
                members {
                    id
                    user_id
                    role {
                        id
                        title
                    }
                    user {
                        id
                        name
                        avatar_image
                    }
                    role {
                      id
                    }
                }
                disciplines {
                    id
                    discipline {
                        id
                        title
                        slug
                    }
                }
            }
            friends {
                id
                friend {
                    id
                    name
                    avatar_image
                }
            }
            notifications {
                id
            }
            chats {
                id
            }
        }
    }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      email
      country_id
      avatar_image
    }
  }
`

export const UPDATE_USER_AVATAR = gql`
  mutation UpdateUserAvatar($avatar: Upload!) {
    updateUserAvatar(avatar: $avatar) {
      id
      name
      email
      country_id
      avatar_image
    }
  }
`

export const REMOVE_USER_AVATAR = gql`
  mutation RemoveUserAvatar {
    removeUserAvatar {
      id
      name
      email
      country_id
      avatar_image
    }
  }
`

export const ADD_FRIENDS = gql`
  mutation AddFriends($friendsIds: [Int!]!) {
    addFriends(friends_ids: $friendsIds)
  }
`

export const UNFRIEND = gql`
  mutation Unfriend($id: Int!) {
    unfriend(id: $id)
  }
`

export const SAVE_FRIEND_REQUEST = gql`
  mutation SaveFriendRequest($id: Int!, $status: Boolean!) {
    saveFriendRequest(id: $id, status: $status)
  }
`

export const SAVE_SOCIAL_LINKS = gql`
  mutation SaveSocialLink($input: [SaveSocialLinkInput!]!) {
    saveSocialLink(input: $input) {
      id
      link_type_id
      url
    }
  }
`

export const FETCH_USERS = gql`
  query Users($where: UsersWhereInput!) {
    users(where: $where) {
      id
      name
      email
      avatar_image
      country_id
      links {
        id
        url
        link_type_id
      }
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPasswordEmail($email: String!) {
    sendResetPasswordEmail(input: { email: $email }) {
      status
      message
    }
  }
`

export const TOKEN_CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $email: String!
    $token: String!
    $password: String!
    $repeatPassword: String!
  ) {
    changePasswordWithToken(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $repeatPassword
      }
    ) {
      status
    }
  }
`
