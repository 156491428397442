import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      alias: '/home',
    },

    // *===============================================---*
    // *--------- PLAYER -----------------------------*
    // *===============================================---*
    {
      path: '/player/:id',
      name: 'player-profile',
      component: () => import('@/views/player/PlayerProfile.vue'),
      meta: {
        pageTitle: 'Player',
        breadcrumb: [
          {
            text: 'Player',
            active: true,
          },
          {
            active: true,
            dynamic: true,
            dynamicProperty: 'playerName',
            loading: true,
          },
        ],
      },
    },
    {
      path: '/account-settings/:tab',
      name: 'account-settings',
      component: () => import('@/views/account/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account',
        breadcrumb: [
          {
            text: 'Account settings',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- TEAM -----------------------------*
    // *===============================================---*
    {
      path: '/team/:id/overview',
      name: 'team-profile',
      component: () => import('@/views/team/TeamProfile.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Team',
            active: true,
          },
          {
            active: true,
            dynamic: true,
            dynamicProperty: 'teamName',
            loading: true,
          },
        ],
      },
    },
    {
      path: '/team/create',
      name: 'team-create',
      component: () => import('@/views/team/TeamAdd.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Team',
            active: true,
          },
        ],
      },
    },
    {
      path: '/team/:id/edit',
      name: 'team-edit',
      component: () => import('@/views/team/TeamEdit.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Team',
            active: true,
          },
          {
            dynamic: true,
            dynamicProperty: 'teamName',
            loading: true,
          },
        ],
      },
    },
    {
      path: '/team/:id/members/edit',
      name: 'team-members-edit',
      component: () => import('@/views/team/TeamMembersEditModal.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Team profile',
            active: true,
          },
        ],
      },
    },

    // *===============================================---*
    // *--------- ORGANIZER -----------------------------*
    // *===============================================---*
    {
      path: '/organizer/:id/overview',
      name: 'organizer-profile',
      component: () => import('@/views/organizer/OrganizerProfile.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Organizer profile',
          },
          {
            active: true,
            dynamic: true,
            dynamicProperty: 'organizerName',
            loading: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- TOURNAMENT -----------------------------*
    // *===============================================---*
    {
      path: '/:game/tournaments',
      name: 'tournaments-search',
      component: () => import('@/views/tournament/TournamentSearch.vue'),
    },
    {
      path: '/:game/tournaments/:id',
      name: 'tournament-overview',
      component: () => import('@/views/tournament/TournamentPage.vue'),
      meta: {
        navActiveLink: 'tournament-search',
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        breadcrumb: [
          {
            text: 'Tournaments',
            active: true,
          },
        ],
      },
      redirect: { name: 'tournament-info' },
      children: [
        {
          path: 'info',
          name: 'tournament-info',
          component: () => import('@/views/tournament/TournamentInfoView.vue'),
          meta: {
            navActiveLink: 'tournament-search',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            breadcrumb: [
              {
                text: 'Tournaments',
                to: { name: 'tournaments-search' },
              },
              {
                text: '',
                active: true,
                dynamic: true,
                dynamicProperty: 'tournamentName',
                loading: true,
              },
            ],
          },
        },
        {
          path: 'matches',
          name: 'tournament-matches',
          component: () => import('@/views/tournament/TournamentMatchesView.vue'),
          meta: {
            navActiveLink: 'tournament-search',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            breadcrumb: [
              {
                text: 'Tournaments',
                to: { name: 'tournaments-search' },
              },
              {
                text: '',
                active: true,
                dynamic: true,
                dynamicProperty: 'tournamentName',
                loading: true,
              },
            ],
          },
          children: [
            {
              path: 'overview',
              name: 'tournament-matches-overview',
              component: () => import('@/views/tournament/TournamentMatchesView.vue'),
              meta: {
                contentRenderer: 'sidebar-left',
                contentClass: 'email-application',
                breadcrumb: [
                  {
                    text: 'Tournaments',
                    to: { name: 'tournaments-search' },
                  },
                  {
                    text: '',
                    active: true,
                    dynamic: true,
                    dynamicProperty: 'tournamentName',
                    loading: true,
                  },
                ],
              },
            },
          ],
        },
        {
          path: 'bracket',
          name: 'tournament-bracket',
          component: () => import('@/views/tournament/TournamentBracketView.vue'),
          meta: {
            navActiveLink: 'tournament-search',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            breadcrumb: [
              {
                text: 'Tournaments',
                to: { name: 'tournaments-search' },
              },
              {
                text: '',
                active: true,
                dynamic: true,
                dynamicProperty: 'tournamentName',
                loading: true,
              },
            ],
          },
        },
        {
          path: 'standings',
          name: 'tournament-standings',
          component: () => import('@/views/tournament/TournamentStandingsView.vue'),
          meta: {
            navActiveLink: 'tournament-search',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            breadcrumb: [
              {
                text: 'Tournaments',
                to: { name: 'tournaments-search' },
              },
              {
                text: '',
                active: true,
                dynamic: true,
                dynamicProperty: 'tournamentName',
                loading: true,
              },
            ],
          },
        },
        {
          path: 'participants/registration',
          name: 'tournament-registration',
          component: () => import('@/views/tournament/TournamentRegistrationView.vue'),
          meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            breadcrumb: [
              {
                text: 'Tournaments',
                to: { name: 'tournaments-search' },
              },
              {
                text: '',
                active: true,
                dynamic: true,
                dynamicProperty: 'tournamentName',
                loading: true,
              },
            ],
          },
        },
        {
          path: 'stages/:stageId',
          name: 'tournament-stage',
          component: () => import('@/views/tournament/TournamentStageView.vue'),
          meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
          },
        },
        {
          path: 'match/:matchId',
          name: 'tournament-match',
          component: () => import('@/views/tournament/TournamentMatchView.vue'),
          meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            breadcrumb: [
              {
                text: 'Tournaments',
                to: { name: 'tournaments-search' },
              },
              {
                text: '',
                active: true,
                dynamic: true,
                dynamicProperty: 'tournamentName',
                loading: true,
              },
            ],
          },
        },
        {
          path: 'participants',
          name: 'tournament-participants',
          component: () => import('@/views/tournament/TournamentParticipantsView.vue'),
          meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            breadcrumb: [
              {
                text: 'Tournaments',
                to: { name: 'tournaments-search' },
              },
              {
                text: '',
                active: true,
                dynamic: true,
                dynamicProperty: 'tournamentName',
                loading: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/tournament/:id/embed',
      name: 'tournament-embed',
      component: () => import('@/views/tournament/TournamentEmbedStandings.vue'),
      meta: {
        layout: 'full',
      },
    },
    // *===============================================---*
    // *--------- ORGANIZER  -----------------------------*
    // *===============================================---*

    {
      path: '/dashboard/:id/tournaments',
      name: 'dashboard-tournaments',
      component: () => import(
        '@/views/dashboard/tournament/OrganizerTournaments.vue'
      ),
      meta: {
        layout: 'dashboard',
        resource: 'Dashboard',
        requiresValidResource: true,
      },

    },
    {
      path: '/dashboard/:id/tournaments/:tournamentId/overview',
      name: 'dashboard-tournament-overview',
      component: () => import(
        '@/views/dashboard/tournament/OrganizerTournamentOverview.vue'
      ),
      meta: {
        layout: 'dashboard',
        resource: 'Dashboard',
        navActiveLink: 'dashboard-tournaments',
      },

    },
    {
      path: '/dashboard/:id/tournaments/:tournamentId/matches',
      name: 'dashboard-tournament-matches',
      component: () => import(
        '@/views/dashboard/tournament/OrganizerTournamentMatches.vue'
      ),
      meta: {
        layout: 'dashboard',
        resource: 'Dashboard',
        navActiveLink: 'dashboard-tournaments',
      },
    },
    {
      path: '/dashboard/:id/tournaments/:tournamentId/bracket',
      name: 'dashboard-tournament-bracket',
      component: () => import(
        '@/views/dashboard/tournament/OrganizerTournamentBracket.vue'
      ),
      meta: {
        layout: 'dashboard',
        resource: 'Dashboard',
        navActiveLink: 'dashboard-tournaments',
      },
    },
    {
      path: '/dashboard/:id/tournaments/:tournamentId/participants',
      name: 'dashboard-tournament-participants',
      component: () => import(
        '@/views/dashboard/tournament/manage/OrganizerTournamentParticipants.vue'
      ),
      meta: {
        layout: 'dashboard',
        resource: 'Dashboard',
        navActiveLink: 'dashboard-tournaments',
      },

    },
    {
      path: '/dashboard/:id/tournaments/:tournamentId/edit',
      name: 'dashboard-tournaments-edit',
      component: () => import(
        '@/views/dashboard/tournament/manage/OrganizerTournamentEditV2.vue'
      ),
      meta: {
        layout: 'dashboard',
        resource: 'Dashboard',
        navActiveLink: 'dashboard-tournaments',
      },
    },
    {
      path: '/dashboard/:id/tournaments/new',
      name: 'dashboard-tournaments-new',
      component: () => import(
        '@/views/dashboard/tournament/manage/OrganizerTournamentNewV2.vue'
      ),
      meta: {
        layout: 'dashboard',
        resource: 'Dashboard',
        navActiveLink: 'dashboard-tournaments',
      },

    },
    {
      path: '/dashboard/:id',
      name: 'dashboard',
      component: () => import(
        '@/views/dashboard/OrganizerDashboardView.vue'
      ),
      meta: {
        layout: 'dashboard',
        resource: 'Dashboard',
      },
    },

    // *===============================================---*
    // *--------- OTHER -----------------------------*
    // *===============================================---*
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register-success',
      name: 'auth-register-success',
      component: () => import('@/views/RegisterSuccess.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'auth-forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset-password',
      name: 'auth-reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/verify-login',
      name: 'verify-login',
      component: () => import('@/views/VerifyLogin.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
