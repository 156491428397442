// eslint-disable-next-line import/no-extraneous-dependencies
import { fetchMe } from '@/api/player/playerService'

export default {
  namespaced: true,
  state: {
    me: null,
    loading: true,
  },
  getters: {
    getMe: state => state.me,
    isLoading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_DATA: (state, payload) => {
      state.me = payload
    },
  },
  actions: {
    async resetMe({ commit }) {
      commit('SET_DATA', null)
    },
    async fetchMe({
      commit,
    }) {
      commit('SET_LOADING', true)

      const { data } = await fetchMe()

      if (data) {
        data.avatar_image = data.avatar_image
          ? `${data.avatar_image}?${Date.now()}`
          : null
      }

      commit('SET_LOADING', false)

      commit('SET_DATA', data)
    },
  },
}
