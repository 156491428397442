// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const FETCH_TOURNAMENTS = gql`
    query Tournaments($where: SearchTournamentWhereInput!) {
        tournaments(where: $where) {
            id
            name
            participants_number
            participant_type_id
            start_at
            enable_checkin
            enable_registration
            registration_open_at
            registration_close_at
            checkin_start_at
            checkin_close_at
            entry_fee
            timezone_id
            discipline {
                id
                title
                image
                slug
            }
            platforms {
                id
                title
                slug
            }
            prizes
            status_id
            participants {
                id
                participant {
                    ... on User {
                        id
                        name
                    }
                    ... on Team {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const REMOVE_TEAM_AVATAR = gql`
    mutation RemoveTeamAvatar($id: Int!) {
        removeTeamAvatar(team_id: $id)
    }
`

export const UPDATE_TEAM = gql`
    mutation UpdateTeam($input: SaveTeamInput!) {
        updateTeam(input: $input) {
            id
        }
    }
`

export const LEAVE_TEAM = gql`
    mutation LeaveTeam($teamId: Int!) {
        leaveTeam(team_id: $teamId)
    }
`

export const REQUEST_TEAM_MEMBERSHIP = gql`
    mutation RequestTeamMembership($teamId: Int!) {
        requestTeamMembership(team_id: $teamId)
    }
`

export const GET_TOURNAMENT_BY_ID = gql`
    query Tournament($id: Int!) {
        tournament(id: $id) {
            id
            name
            organizer_id
            participant_type_id
            participants_number
            players_per_team
            enable_checkin
            start_at
            timezone_id
            info
            rules
            tournament_type_id
            enable_registration
            enable_checkin
            checkin_end_on_tournament_start
            registration_open_at
            registration_close_at
            checkin_start_at
            checkin_close_at
            prizes
            has_participation_fee
            entry_fee
            settings {
                enable_two_stage_tournament
                first_stage_number_of_groups
                first_stage_participants_per_group
                first_stage_participants_to_advance_in_next_stage
                first_stage_rank_in_groups_by
                first_stage_group_tie_brakes1
                first_stage_group_tie_brakes2
                first_stage_group_tie_brakes3
                first_stage_structure
                first_stage_general_format
                second_stage_structure
                second_stage_general_format
                mandatory_match_attachment
                auto_confirm_join_request
            }
            organizer_fee
            organizer {
                id
                name
            }
            registrations {
                id
                is_confirmed
                participant_type
                participant_id
                participant {
                    __typename
                    ... on User {
                  id
                  name
                }
                ... on Team {
                  id
                  name
                  members {
                    id
                    role {
                      id
                      title
                    }
                    user_id
                    user {
                      id
                      name
                      avatar_image
                    }
                  }
                }
                }
            }
            discipline {
                id
                image
                title
                slug
                category {
                    id
                    title
                }
            }
            staff {
                id
                user_id
                user {
                    id
                    name
                }
            }
            platforms {
                id
                title
            }
            participants {
                id
                tournament_id
                participant_type
                participant_id
                checked_in
                status
                participant {
                    __typename
                    ... on Team {
                        id
                        name
                        email
                        avatar_image
                        members {
                          id
                          role {
                            id
                            title
                          }
                          user_id
                          user {
                            id
                            name
                            avatar_image
                          }
                        }
                    }
                    ... on User {
                        id
                        name
                        email
                        avatar_image
                    }
                }
                lineup {
                    id
                    player_id
                    name
                    player {
                        id
                        name
                    }
                }
                name
                email
            }
            status_id
            stages {
                id
                tournament_id
                index
                name
                type {
                    id
                    title
                }
                format {
                    id
                    title
                }
                auto_placement_enabled
            }
            rankings {
                id
                participant_id
                participant {
                    id
                    name
                    participant {
                      __typename
                      ... on Team {
                          id
                          name
                          email
                          avatar_image
                          members {
                            id
                            role {
                              id
                              title
                            }
                            user_id
                            user {
                              id
                              name
                              avatar_image
                            }
                          }
                      }
                      ... on User {
                          id
                          name
                          email
                          avatar_image
                      }
                  }
                }
                rank
                points
                wins
                draws
                losses
            }
            matches {
                id
                index
                opponent1_id
                opponent2_id
                winner_id
                loser_id
                results {
                    id
                    elapsed_time
                    participant {
                        id
                        participant_type
                        name
                        email
                        participant {
                          ... on Team {
                            id
                            name
                            }
                            ... on User {
                            id
                            name
                            }
                        }
                      }
                   
                  }
            }
        }
    }
`

export const GET_MY_TOURNAMENT_BY_ID = gql`
    query MyTournament($organizerId: Int!, $tournamentId: Int!) {
        myTournament(organizer_id: $organizerId, tournament_id: $tournamentId) {
            id
            name
            organizer_id
            participant_type_id
            participants_number
            players_per_team
            enable_checkin
            start_at
            timezone_id
            info
            rules
            tournament_type_id
            enable_registration
            enable_checkin
            checkin_end_on_tournament_start
            registration_open_at
            registration_close_at
            checkin_start_at
            checkin_close_at
            prizes
            has_participation_fee
            entry_fee
            settings {
                enable_two_stage_tournament
                first_stage_number_of_groups
                first_stage_participants_per_group
                first_stage_participants_to_advance_in_next_stage
                first_stage_rank_in_groups_by
                first_stage_group_tie_brakes1
                first_stage_group_tie_brakes2
                first_stage_group_tie_brakes3
                first_stage_structure
                first_stage_general_format
                second_stage_structure
                second_stage_general_format
                mandatory_match_attachment
                auto_confirm_join_request
            }
            organizer_fee
            organizer {
                name
            }
            registrations {
                id
                is_confirmed
                participant_type
                participant_id
                participant {
                  ... on User {
                        id
                        name
                  }
                  ... on Team {
                    id
                    name
                  }
                }
            }
            discipline {
                id
                image
                title
                slug
                category {
                    id
                    title
                }
            }
            staff {
                user_id
                user {
                    id
                    name
                }
            }
            platforms {
                id
                title
            }
            participants {
                id
                tournament_id
                participant_type
                participant_id
                checked_in
                status
                seed
                participant {
                    ... on Team {
                        id
                        name
                        email
                        avatar_image
                    }
                    ... on User {
                        id
                        name
                        email
                        avatar_image
                    }
                }
                lineup {
                    id
                    player_id
                    name
                    player {
                        id
                        name
                    }
                }
                name
                email
            }
            status_id
            rankings {
                id
                rank
                participant_id
                participant {
                    id
                    name
                }
            }
            matches {
                  id
                  status_id
                  opponent1 {
                      participant {
                        ... on Team {
                        id
                        name
                        }
                        ... on User {
                        id
                        name
                        }
                    }
                  }
                  opponent2 {
                      participant {
                        ... on Team {
                        id
                        name
                        }
                        ... on User {
                        id
                        name
                        }
                      }
                  }
                  result {
                      id
                      opponent1_score
                      opponent2_score
                      opponent1_result
                      opponent2_result
                  }
                  results {
                    id
                    elapsed_time
                    participant {
                        id
                        participant_type
                        name
                        email
                        participant {
                          ... on Team {
                            id
                            name
                            }
                            ... on User {
                            id
                            name
                            }
                        }
                      }
                   
                  }
              }
        }
    }
`

export const WITHDRAW_TOURNAMENT = gql`
    mutation WithdrawTournament($tournamentId: Int!, $participantId: Int!) {
        withdrawTournament(tournament_id: $tournamentId, participant_id: $participantId)
    }
`

export const JOIN_TOURNAMENT = gql`
mutation JoinTournament(
  $input: JoinTournamentInput!
) {
  joinTournament(input: $input) 
}
`

export const SAVE_REGISTRATION = gql`
mutation SaveTournamentRegistration(
  $input: SaveTournamentRegistrationInput!
) {
  saveTournamentRegistration(input: $input)
}
`

export const SAVE_MATCH_RESULT = gql`
    mutation SaveMatchResult(
        $input: SaveMatchResultInput!
    ) {
        saveMatchResult(input: $input) {
            id
        }
    }
`

export const SAVE_RACE_MATCH_RESULT = gql`
    mutation SaveRaceMatchResult(
        $input: SaveMatchResultInput!
    ) {
        saveRaceMatchResult(input: $input) {
            id
        }
    }
`

export const SAVE_PARTICIPANT_MATCH_RESULT = gql`
    mutation SaveParticipantMatchResult(
        $input: SaveParticipantMatchResultInput!
    ) {
        saveParticipantMatchResult(input: $input) {
            id
        }
    }
`

export const FETCH_MATCH = gql`
        query Match($id: Int!) {
          match(id: $id) {
            id
            stage_id
            group_id
            round_id
            index
            winner_id
            opponent1_id
            opponent1 {
              id
              name
              participant_type
              participant_id
              participant {
                    ... on Team {
                    id
                    name
                    avatar_image
                    }
                    ... on User {
                    id
                    name
                    avatar_image
                    }
                
              }
              lineup {
                  player_id
                  name
                  player {
                    id
                    name
                  }
                }
            }
            opponent2_id
            opponent2 {
              id
              name
              participant_type
              participant_id
              participant {
                    ... on Team {
                    id
                    name
                    avatar_image
                    }
                    ... on User {
                    id
                    name
                    avatar_image
                    }
              }
              lineup {
                  player_id
                  name
                  player {
                    id
                    name
                  }
                }
            }
            is_bye
            status_id
            status {
              title
            }
            result {
              id
              opponent1_result
              opponent2_result
              opponent1_score
              opponent2_score
              dispute_resolved_by
              dispute_resolution_notes
              participant_results {
                participant_id
                opponent1_score
                opponent2_score
                opponent1_result
                opponent2_result
                opponent1_forfeit
                opponent2_forfeit
                status
                score_screenshot
              }
            }
          }
        }
    `

export const FETCH_TOURNAMENT_MATCHES = gql`
        query Matches($id: Int!) {
          matches(tournament_id: $id) {
            id
            stage_id
            group_id
            index
            round {
              id 
              index
            }
            group {
                id
                index
              }
            winner_id
            opponent1_id
            opponent1 {
              id
              name
              participant_type
              participant_id
              participant {
                    ... on Team {
                    id
                    name
                    avatar_image
                    }
                    ... on User {
                    id
                    name
                    avatar_image
                    }
              }
              lineup {
                  player_id
                  name
                  player {
                    id
                    name
                  }
              }
              result {
                id
                match_result_id
                participant_id
                opponent1_forfeit
                opponent2_forfeit
                opponent1_score
                opponent2_score
                opponent1_result
                opponent2_result
                status
                score_screenshot
              }
            }
            opponent2_id
            opponent2 {
              id
              name
              participant_type
              participant_id
              participant {
                    ... on Team {
                    id
                    name
                    avatar_image
                    }
                    ... on User {
                    id
                    name
                    avatar_image
                    }
              }
              lineup {
                  player_id
                  name
                  player {
                    id
                    name
                  }
                }
            }
            is_bye
            status_id
            status {
              title
            }
            tournament {
             settings {
                 first_stage_structure
             }
             staff {
               id
               user_id
             }
            }
            result {
              id
              opponent1_result
              opponent2_result
              opponent1_score
              opponent2_score
              dispute_resolved_by
              dispute_resolution_notes
              participant_results {
                participant_id
                opponent1_score
                opponent2_score
                opponent1_result
                opponent2_result
                opponent1_forfeit
                opponent2_forfeit
                status
                score_screenshot
              }
            }
          }
        }
    `

export const FETCH_DASHBOARD_ORGANIZER_TOURNAMENTS = gql`
        query MyTournaments($where: MyTournamentsWhereInput!) {
          myTournaments(where: $where) {
            id
            name
            slug
            participants_number
            participant_type_id
            start_at
            entry_fee
            prizes
            status {
              id
              title
            }
            staff {
              id
              tournament_id
              user {
                id
                name
              }
            }
            platforms {
              id
              title
            }
            discipline {
              id
              title
              image
            }
          }
        }
      `

export const FETCH_TOURNAMENT_BRACKET = gql`
    query Bracket($id: Int!) {
        bracket(tournament_id: $id) {
            id
            next_match_id
            group_id
            group {
                id
                index
            }
            match_id
            result {
                id
                opponent1_score
                opponent2_score
                opponent1_result
                opponent2_result
            }
            round_id
            round {
                id
                index
                name
                format_id
            }
            opponent1_id
            opponent1 {
                id
                participant {
                    ... on Team {
                        id
                        name
                    }
                    ... on User {
                        id
                        name
                    }
                }
                name
                email
            }
            opponent2_id
            opponent2 {
                id
                participant {
                    ... on Team {
                        id
                        name
                    }
                    ... on User {
                        id
                        name
                    }
                }
                name
                email
            }
        }
    }
`

export const FETCH_ORGANIZER_TOURNAMENT_BRACKET = gql`
    query OrganizerBracket($id: Int!) {
        organizerBracket(tournament_id: $id) {
            id
            next_match_id
            group_id
            group {
                id
                index
            }
            match_id
            result {
                id
                opponent1_score
                opponent2_score
                opponent1_result
                opponent2_result
            }
            round_id
            round {
                id
                index
                name
                format_id
            }
            opponent1_id
            opponent1 {
                id
                participant {
                    ... on Team {
                        id
                        name
                    }
                    ... on User {
                        id
                        name
                    }
                }
                name
                email
            }
            opponent2_id
            opponent2 {
                id
                participant {
                    ... on Team {
                        id
                        name
                    }
                    ... on User {
                        id
                        name
                    }
                }
                name
                email
            }
        }
    }
`

export const SAVE_TOURNAMENT_INVITE = gql`
    mutation SaveTournamentInvite($token: String!, $accept: Boolean!) {
        saveTournamentInvite(token: $token, accept: $accept)
    }
`
